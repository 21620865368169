<template>
<div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <datetime
        class="date-time-input theme-main"
        :class="['input-custom', { error: isValidate }]"
        :type="type"
        :format="format"
        :placeholder="placeholder"
        :inputId="id"
        :name="name"
        v-bind:value="value"
        :required="required"
        @input="$emit('input', $event)"
        :maxDatetime="maxDatetime"
        :minDatetime="minDatetime"
        :disabled="isDisabled"
        value-zone="local"
      ></datetime>
    </div>
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณาเลือกวันที่</span>
      <span class="text-error" v-else-if="v.minValue == false">
        วันเริ่มต้นต้องน้อยกว่าวันสิ้นสุด
      </span>
      <span class="text-error" v-else-if="v.maxValue == false">
        วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
      </span>
      <span class="text-error" v-else-if="v.minDateTime == false">
        วันและเวลาสิ้นสุดต้องมากกว่าวันและเวลาเริ่มต้น
      </span>
      <span class="text-error" v-else-if="v.maxDateTime == false">
        วันและเวลาเริ่มต้นต้องน้อยกว่าวันและเวลาสิ้นสุด
      </span>
      <span class="text-error" v-else-if="v.minOrEqualValue == false">
        วันสิ้นสุดต้องมากกว่าหรือเท่ากับวันเริ่มต้น
      </span>
      <span class="text-error" v-else-if="v.maxOrEqualValue == false">
        วันเริ่มต้นต้องน้อยกว่าหรือเท่ากับวันสิ้นสุด
      </span>
      <span class="text-error" v-else-if="v.maxOrEqualToday == false">
        วันเริ่มต้นต้องน้อยกว่าหรือเท่ากับวันปัจจุบัน
      </span>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";

export default {
  props: {
    textFloat: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    isTime: {
      required: false,
      type: Boolean
    },
    isDisabled: {
      required: false,
      type: Boolean
    },
    required: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    id: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    type: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: String
    },
    detail: {
      required: false,
      type: String
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    maxDatetime	: {
      required: false,
    },
    minDatetime	: {
      required: false,
    },
    v: {
      required: false,
      type: Object
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    className: {
      required: false,
      type: String
    },
  },
  template: "...",
  components: {
    datetime: Datetime
  },
  data() {
    return {
      format : "dd/MM/yyyy HH:mm"
    };
  },
  mounted () {
    if(this.type == 'date'){
      this.format = "dd/MM/yyyy"
    }else if(this.type == 'datetime'){
      this.format = "dd/MM/yyyy HH:mm"
    }
  },
};
</script>

<style scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}
.input-custom > label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
  width: 100%;
}
.input-custom > input {
  color: #16274a;
  /* background-color: white; */
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
}
.input-custom > input[size="lg"] {
  height: 45px;
}
::v-deep .input-custom.error > .vdatetime-input {
  border-color: red !important;
}
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}

.input-date-time {
  background-color: #f8f8f8;
  border-radius: 10px;
  margin: 15px 0px;
  padding: 3px 15px;
  border: 2px solid #f8f8f8 !important;
}
.input-date-time.error {
  border-color: red !important;
}
.input-date-time > label {
  color: #424242;
  font-size: 14px;
  margin-bottom: 0;
}
.input-date-time > input {
  color: rgba(22, 39, 74, 0.4);
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.date-time-input {
  display: block;
  border: none;
}
::v-deep .date-time-input > .vdatetime-input {
  width: 100% !important;
  color: #16274a;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
  cursor: pointer;
}
.date-time-input > .vdatetime-input {
  background-color: #f8f8f8 !important;
  border: 0 !important;
}
::v-deep .theme-main .vdatetime-popup__header,
::v-deep .theme-main .vdatetime-calendar__month__day--selected > span > span,
::v-deep
  .theme-main
  .vdatetime-calendar__month__day--selected:hover
  > span
  > span {
  background: #3f51b5 !important;
}

::v-deep .theme-main .vdatetime-year-picker__item--selected,
::v-deep .theme-main .vdatetime-time-picker__item--selected,
::v-deep .theme-main .vdatetime-popup__actions__button {
  color: #3f51b5 !important;
}
@media (max-width: 767.98px) {
  .input-custom > label {
    font-size: 15px;
  }
  .input-date-time {
    margin-top: 0;
  }
}
</style>

